<template>
  <div class="h-100">
    <sidebar/>
    <div class="d-flex vh-100 flex-column">
      <navbar/>
      <keep-alive>
        <router-view class="flex-grow-1"></router-view>
      </keep-alive>
    </div>
    
  </div>
</template>

<script>
import Navbar from '@/components/base/Navbar.vue'
import Sidebar from '@/components/base/Sidebar.vue'

export default {
  components:{
    Navbar,
    Sidebar,
  },

}
</script>
